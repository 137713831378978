import { getGeolocation } from '../../services/get-geolocation';
import { GDPR, USP } from './constants';
import { createButton } from './create-button';
import addScript from '../../services/script-loader.js';

export const createCmpButton = (placeholder, className, buttonType, buttonText) => {
  if (window.__tcfapi !== undefined) {
    window.__tcfapi(`addEventListener`, 2, ({ gdprApplies }) => {
      if (gdprApplies && buttonType === GDPR) {
        createButton(placeholder, className, buttonType, buttonText);
      }
    });
    window.__uspapi(`uspPing`, 1, (obj, status) => {
      if (status && obj.mode.includes(USP) && buttonType === USP) {
        createButton(placeholder, className, buttonType);
      }
    });
  }
};

const countries = [
  `BG`,
  `HR`,
  `CY`,
  `CZ`,
  `DK`,
  `EE`,
  `FI`,
  `FR`,
  `DE`,
  `GR`,
  `HU `,
  `IE`,
  `IT`,
  `LV`,
  `LT`,
  `LU`,
  `MT`,
  `NL`,
  `PL`,
  `PT`,
  `RO`,
  `SK`,
  `SI`,
  `ES`,
  `SE`,
  `GB`,
];

export const initQuantCast = async () => {
  const geo = await getGeolocation();

  if (countries.includes(geo) && window.Site.cmpPath) {
    addScript(window.Site.cmpPath, () => {
      const cmpButton = document.querySelector(`#cmp-button-placeholder`);
      const cmpLink = document.querySelector(`#cmp-link-placeholder`);

      if (cmpButton) {
        createCmpButton(cmpButton, `c-footer__privacy-button`, GDPR, `Change privacy settings`);
      }

      if (cmpLink) {
        createCmpButton(cmpLink, `c-static-page__privacy-link`, USP);
      }
    });
  }
};
