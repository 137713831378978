import addCopyrightLink from './modules/copyright-link.js';
import addScript from './services/script-loader.js';
import { initQuantCast } from './modules/quantcast';
import { hideHeaderOnScroll } from './modules/sticky-header';
import { isMobile } from './services/viewport';
import { hideGlobalMask, onGlobalMaskClick, showGlobalMask } from './modules/popup-mask.js';

document.addEventListener(`copy`, addCopyrightLink);

/* register service worker */
if (window.Site.oneSignalAppId) {
  addScript(`https://cdn.onesignal.com/sdks/OneSignalSDK.js`, () => {
    window.OneSignal.push([
      `init`,
      {
        appId: window.Site.oneSignalAppId,
      },
    ]);
  });
}

initQuantCast();

if (`serviceWorker` in navigator) {
  window.addEventListener(`load`, () => {
    navigator.serviceWorker.register(`/one-signal-service-worker.js`);
  });
}

/* temporary hard header scripts */
const $headerMenuButton = document.querySelector(`.js-header-menu-button`);
const $globalMenu = document.querySelector(`.global-menu`);
const $headerSubscribeButton = document.querySelector(`.js-header-subscribe-button`);
const $headerSubscribeContainer = document.querySelector(`.js-header-subscribe-container`);
const $header = document.querySelector(`.js-header`);

const getVh = () => window.innerHeight * 0.01;
document.documentElement.style.setProperty(`--vh`, `${getVh()}px`);

window.addEventListener(`resize`, () => {
  document.documentElement.style.setProperty(`--vh`, `${getVh()}px`);
});

function showMenu() {
  $headerMenuButton?.classList.add(`c-header-menu-button--open`);
  $globalMenu?.classList.add(`global-menu--opened`);
}

function hideMenu() {
  $headerMenuButton?.classList.remove(`c-header-menu-button--open`);
  $globalMenu?.classList.remove(`global-menu--opened`);
}

function showHeaderSubscribe() {
  if (!$headerSubscribeButton || !$headerSubscribeContainer) {
    return;
  }

  $headerSubscribeButton.classList.add(`c-header-subscribe__click-container--active`);
  $headerSubscribeContainer.classList.add(`c-header-subscribe__form-container--show`);
}

function hideHeaderSubscribe() {
  if (!$headerSubscribeButton || !$headerSubscribeContainer) {
    return;
  }

  $headerSubscribeButton.classList.remove(`c-header-subscribe__click-container--active`);
  $headerSubscribeContainer.classList.remove(`c-header-subscribe__form-container--show`);
}

if ($header && isMobile()) {
  hideHeaderOnScroll($header);
}

if ($headerMenuButton) {
  $headerMenuButton.addEventListener(`click`, () => {
    if ($headerMenuButton.classList.contains(`c-header-menu-button--open`)) {
      hideMenu();
      hideGlobalMask();
    } else {
      showMenu();
      showGlobalMask();
      hideHeaderSubscribe();
    }
  });
}

if ($headerSubscribeButton && $headerSubscribeContainer) {
  $headerSubscribeButton.addEventListener(`click`, () => {
    if ($headerSubscribeButton.classList.contains(`c-header-subscribe__click-container--active`)) {
      hideHeaderSubscribe();
      hideGlobalMask();
    } else {
      showHeaderSubscribe();
      showGlobalMask();
      hideMenu();
    }
  });
}

onGlobalMaskClick(() => {
  hideHeaderSubscribe();
  hideMenu();
});

const $sponsorBannerLink = document.querySelector(`.js-sponsor-link`);

if ($sponsorBannerLink) {
  $sponsorBannerLink.addEventListener(`click`, () => {
    window.gtag?.(`event`, `click`, {
      type: `sponsor-banner`,
    });
  });
}
